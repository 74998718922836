<template lang="pug">
  v-table
    v-table-head
      th.table-sort-arrows-hover(
        @click="onSortPositions('name', sortedLabels.name)")
        | {{ 'ui.labels.name'  | translate }}
        v-table-sorter(
          :show="currentSortLabel === 'name'"
          :toggled="sortedLabels.name")
      th.text-center.table-sort-arrows-hover(style="width:200px"
        @click="onSortPositions('employeeCount', sortedLabels.employeeCount)")
        | {{'pages.administration.employee_count' | translate }}
        v-table-sorter(
          :show="currentSortLabel === 'employeeCount'"
          :toggled="sortedLabels.employeeCount")
      th(style="width:100px")
    v-table-body(
      :cols="3"
      :empty="!filtered.length"
      :loaded="loaded"
      :no-result="!filtered.length && !!search.length")
      v-table-row(
        v-for="(item, index) in filtered"
        :key="index")
        td {{ item.name }}
        td.text-center {{ item.employeeCount || 0 }}
        td.text-right
          v-btn(
            icon
            v-tooltip="$t('infotext.edit')"
            @click="openPosition(item)")
            i.fa.fa-pencil-alt
          v-btn(
            icon
            danger
            v-tooltip="$t('infotext.remove')"
            @click="onItemDelete(item)")
            i.fa.fa-trash-alt

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { MODAL_TYPES } from '@/util/constants'

export default {
  name: 'PositionsTable',

  props: {
    search: String
  },

  async mounted () {
    // TODO: Refoctor this code
    const { headers } = await this.loadPositions()
    if (headers['x-sort-by'] && headers['x-sort-by'][0] !== '-') {
      this.sortedLabels[headers['x-sort-by']] = true
      this.currentSortLabel = headers['x-sort-by']
    } else if (headers['x-sort-by']) {
      this.currentSortLabel = headers['x-sort-by'].replace('-', '')
    }
    this.loaded = true
  },

  data: () => ({
    loaded: false,
    sortedLabels: {
      name: false,
      employeeCount: false
    },
    currentSortLabel: null
  }),

  methods: {
    ...mapActions('positions', [
      'loadPositions',
      'openPosition',
      'deletePosition'
    ]),

    ...mapActions('modals', [
      'openConfirmModal'
    ]),

    onItemDelete (item) {
      const options = {
        content: {
          title: this.$t('ui.modals.delete_message_with_params', { name: item.name })
        },
        props: {
          type: MODAL_TYPES.WARNING_RED
        },
        buttons: {
          apply: {
            label: 'ui.buttons.delete',
            callback: this.deletePosition,
            params: item
          }
        }
      }
      this.openConfirmModal(options)
    },

    onSortPositions (fieldName, order) {
      // TODO: Refactor this code
      const params = {
        sort: order ? `-${fieldName}` : fieldName
      }
      this.loadPositions(params)
      for (const key in this.sortedLabels) {
        if (key === fieldName) {
          this.sortedLabels[key] = !this.sortedLabels[key]
          this.currentSortLabel = fieldName
        } else {
          this.sortedLabels[key] = false
        }
      }
    }
  },

  computed: {
    ...mapGetters('positions', [
      'positions'
    ]),

    filtered () {
      return this.positions
        .filter(item => item.name
          .toLowerCase()
          .includes(this.search
            .toLowerCase()))
    }
  }
}
</script>

<style lang="scss">
</style>
